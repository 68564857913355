.panel-palen > .panel-heading {
    background-color: $brand-palen;
}

.history-badge {
    position: absolute;
    left: 0;
    width: $timeline-badge-size;
    height: $timeline-badge-size;
    border-radius: 500px;
    line-height: $timeline-badge-size;
    font-size: 1em;
    text-align: center;
    color: #fff;
    background-color: #999;
    z-index: 100;

    // Color variants
    &.primary {
        background-color: $brand-primary;

        &.outline {
            border: 1px solid $brand-primary;
            background-color: white;
            color: $brand-primary;
        }
    }
    &.success {
        background-color: $brand-success;

        &.outline {
            border: 1px solid $brand-success;
            background-color: white;
            color: $brand-success;
        }
    }
    &.warning { background-color: $brand-warning; }
    &.danger  { background-color: $brand-danger;  }
    &.info    { background-color: $brand-info;    }

    &.inverse { background-color: $brand-inverse; }
    &.green   { background-color: $brand-green;   }
    &.pink    { background-color: $brand-pink;    }
    &.purple  { background-color: $brand-purple;  }
    &.dark    { background-color: $brand-dark;    }
    &.yellow  { background-color: $brand-yellow;  }
}

.modal-dialog {
    /*margin-top:0px;*/
    width:400px;
}

.event-modal-header {
    margin-top: 0px;
    &.success {
        background-color: $brand-success-light;
        color: white;
    }
    &.warning {
        background-color: $brand-warning-light;
        color: white;
    }
    &.primary {
        background-color: $brand-primary-light;
        color: white;
    }
    &.default {
        background-color:#999;
        color:white;
    }

}

ul > li > ul > li > ul > li > a > span {
  	font-weight: 400;
  }

  ul > li > ul > li > ul > li > a  {
    	padding-top: 5px !important;
        padding-bottom: 5px !important;
        padding-left: 60px !important;
    }



  ul > li > ul > li > ul > li  {
    	background-color: #F5F7FA;
    }

    ul > li > ul > li > ul > li:hover  {
      	background-color: rgba(127, 116, 193, 0.30);
        color: black;
      }
.list-group-item:first-child {  // overrides parent style
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}

.pointer {
    cursor: pointer;
}

.hiddenDetail.ng-show {
    transition: 0.5s linear all;
    opacity:1;
    height:200px;
}

.hiddenDetail.ng-hide {
    opacity: 0;
    height: 0px;
}

.hiddenDetail {
    /*opacity:0;*/
//    height: 0px;
}

.hiddenDetail.ng-hide-active {
    display: block !important;
}

.label-default {
    background-color: #999;
    color: white !important;
}

tr.lineItem:hover {
    background-color:white !important;
}

.m-t-20 {
    margin-top:20px !important;
}
.m-t-15 {
    margin-top:15px !important;
}
.m-t-10 {
    margin-top:10px !important;
}
.m-t-5 {
    margin-top:5px !important;
}

.chosen-single {
    text-align: center !important;
}

.chosen-drop {
    text-align: center !important;
}

.itemTypeMenuItem > span {
    color: #7266ba;
}

.itemTypeMenuItem:hover > span {
    color: white;
}