/*!
 *
 * Angle - Bootstrap Admin App + AngularJS
 *
 * Version: 3.3.1
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: https://wrapbootstrap.com/help/licenses
 *
 */

// Override bootstrap variables
@import "app/variables";

// Bootstrap
@import "bootstrap/bootstrap/_mixins";

// Global definition of media queries
@import "app/media-queries";
// Utilities
@import "app/utils-definitions";

// Bootstrap reset
@import "app/bootstrap-reset";

// Typography
@import "app/typo";

// Layout
@import "app/layout";
@import "app/layout-extra";
@import "app/layout-animation";
@import "app/top-navbar";
@import "app/sidebar";
@import "app/offsidebar";


// Components
@import "app/preloader";
@import "app/breadcrumbs";
@import "app/loading-bar";
@import "app/animate";
@import "app/form-elements";
@import "app/form-validation";

// Utilities
@import "app/utils";

// Print CSS
@import "app/print";

// My additions
@import "app/timeline";
@import "app/panels";
@import "app/spinner";
@import "app/circles";
@import "app/custom";
@import "app/plugins";
